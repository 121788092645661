a {
  color: inherit;
  text-decoration: none;
}

input, select, textarea, button, div, a {
  :focus, :active {
      outline: none;
}
}
